import { Box, Card, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Download, Eye, File } from '@phosphor-icons/react';
import { useAuth } from 'modules/auth/hooks';
import { LoadingSkeleton, RoqLink, Table, TableColumnInterface } from 'modules/common/components';
import { DetailStyledLink } from 'modules/common/components/detail-styled-link/detail-styled-link.component';
import { EllipseTextComponent } from 'modules/common/components/ellipse-text';
import { FilterButtonComponent } from 'modules/common/components/filter-button/filter-button.component';
import { ResetButton } from 'modules/common/components/reset-button/reset-button.component';
import { TableHeaderComponent } from 'modules/common/components/table-header/table-header.component';
import { TableNoData } from 'modules/common/components/table-no-data/table-no-data.component';
import { ToolTipCommon } from 'modules/common/components/tool-tip-common/tool-tip-common.component';
import { ViewAllButtonComponent } from 'modules/common/components/view-all-button/view-all-button.component';
import { OrderEnum } from 'modules/common/enums';
import { DocumentsOrderSortEnum } from 'modules/common/enums/documents-order-sort.enum';
import { useRouter } from 'modules/common/hooks';
import { useDocumentsActions } from 'modules/common/hooks/use-documents-actions.hook';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { FormattedDate } from 'modules/date-time/components';
import { DocumentsDataInterface } from 'modules/documents/interfaces/documents-data.interface';
import { InvoicesFilterSelectionComponent } from 'modules/invoices/components/invoices-filter-selection/invoices-filter-selection.component';
import { InvoicesStatusComponent } from 'modules/invoices/components/invoices-status/invoices-status.component';
import { HeaderRightComponentTypeEnum } from 'modules/invoices/enums/header-right-component-type.enum';
import { InvoicesTablePropsInterface } from 'modules/invoices/interfaces/invoices-table-props.interface';
import React from 'react';
import routes from 'routes';


const DocumentType = ({ type }: { type: DocumentsDataInterface['documentType'] }): React.ReactElement => (
  <>
    <ToolTipCommon title={type}>
      <Stack flexDirection={'row'} alignItems={'center'} alignContent={'center'}>
        <File size={40} color="#a1a1a2" style={{ marginRight: 4, marginBottom: 2 }} />
        <Typography
          sx={{
            fontSize: '13px !important',
            lineHeight: '100% !important',
            letterSpacing: '0.16px !important',
            color: 'black',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '150px',
          }}
        >
          {type}
        </Typography>
      </Stack>
    </ToolTipCommon>
  </>
);


export const InvoicesTableComponent = (props: InvoicesTablePropsInterface): React.ReactElement => {
  const { user } = useAuth();

  const {
    data,
    order = { order: OrderEnum.ASC, sort: DocumentsOrderSortEnum.DUE_DATE },
    totalCount,
    pageSize,
    isLoading,
    pageNumber,
    handlePageChange,
    handleOrderChange,
    filterColumns,
    paginationEnabled,
    isHeaderComponent = false,
    headerRightComponentType,
    handlePageRowsCountChange,
    headerLeftComponent,
    onRightHeaderComponentClick = () => '',
    isFilterSelection,
    onResetClick = () => '',
  } = props;
  const router = useRouter();
  const { push } = router;

  const { downloadFile } = useDocumentsActions();

  const invoicesTableDefaultColumns = (): TableColumnInterface<DocumentsDataInterface>[] => [
    {
      title: 'Document Type',
      field: 'type',
      sorting: false,
      cellStyle: {
        width: '7rem',
        maxWidth: '7rem',
        minWidth: '5rem',
      },
      render: (rowData: DocumentsDataInterface) => <DocumentType type={rowData?.documentType} />,
    },
    {
      title: 'Document ID',
      field: 'documentId',
      sorting: false,
      cellStyle: {
        width: '9rem',
        maxWidth: '10rem',
        minWidth: '8rem',
      },
      render: (rowData: DocumentsDataInterface) => rowData.documentId,
    },
    {
      title: 'Customer PO Number',
      field: 'customerPONumber',
      sorting: false,
      render: (rowData: DocumentsDataInterface) => (
        <EllipseTextComponent textFieldWidth="6rem" text={rowData.customerPoNumber} />
      ),
    },
    {
      title: 'HELM Contract ID',
      field: 'helmContractId',
      sorting: false,
      cellStyle: {
        width: '9rem',
        maxWidth: '10rem',
        minWidth: '8rem',
      },
      render: (rowData: DocumentsDataInterface) => (
        <>
          {rowData?.helmContractId ? (
            <RoqLink
              style={{
                cursor: 'pointer',
                color: '#006173',
              }}
              onClick={() => {
                void push(`/${routes.contracts}/${rowData.helmContractId}/${ContractTypeEnum.FRAME}`);
              }}
            >
              {rowData.helmContractId}
            </RoqLink>
          ) : (
            <Typography
              fontSize={'inherit'}
              color={rowData?.helmContractId ? 'primary' : 'black'}
              fontWeight={rowData?.helmContractId ? 600 : 400}
            >
              {'N/A'}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: 'HELM Order ID',
      field: 'helmOrderId',
      sorting: false,
      cellStyle: {
        width: '9rem',
        maxWidth: '10rem',
        minWidth: '8rem',
      },
      render: (rowData: DocumentsDataInterface) => (
        <>
          {
            <DetailStyledLink href={`/orders/${rowData?.helmOrderId}`} value={rowData?.helmOrderId} />
          }

        </>
      ),
    },
    {
      title: 'Order Type',
      field: 'helmOrderType',
      sorting: false,
      cellStyle: {
        width: '7rem',
        maxWidth: '8rem',
        minWidth: '6rem',
      },
      render: (rowData: DocumentsDataInterface) => (
        <>
          <Typography fontSize={'inherit'} color={'black'}>
            {rowData?.helmContractId ? 'Call off' : 'Spot'}
          </Typography>
        </>
      ),
    },
    {
      title: 'HELM Delivery ID',
      field: 'helmDeliveryId',
      sorting: false,
      cellStyle: {
        width: '9rem',
        maxWidth: '10rem',
        minWidth: '8rem',
      },
      render: (rowData: DocumentsDataInterface) => (
        <>
          <Typography
            fontSize={'inherit'}
            color={rowData?.helmDeliveryId ? 'primary' : 'black'}
            fontWeight={rowData?.helmDeliveryId ? 600 : 400}
          >
            {rowData?.helmDeliveryId || 'N/A'}
          </Typography>
        </>
      ),
    },
    {
      title: 'Product',
      field: 'product',
      sorting: false,
      cellStyle: {
        width: '9rem',
        maxWidth: '10rem',
        minWidth: '8rem',
      },
      render: (rowData: DocumentsDataInterface) => <EllipseTextComponent textFieldWidth="6rem" text={rowData.product} />,
    },
    {
      title: 'Ship To Address',
      field: 'shipTo',
      cellStyle: {
        width: '9rem',
        maxWidth: '10rem',
        minWidth: '8rem',
      },
      sorting: false,
      render: (rowData: DocumentsDataInterface) => (
        <EllipseTextComponent textFieldWidth="6rem" text={rowData.shipTo.join(' ')} />
      ),
    },
    {
      title: 'Net Value',
      field: 'netValue',
      sorting: false,
      cellStyle: {
        width: '7rem',
        maxWidth: '8rem',
        minWidth: '7rem',
      },
      render: (rowData: DocumentsDataInterface) => <EllipseTextComponent text={`${rowData.netValue} ${rowData.currency}`} />,
    },
    {
      title: 'Due Date',
      field: 'dueDate',
      sorting: false,
      cellStyle: {
        width: '6rem',
      },
      render: (rowData: DocumentsDataInterface) =>
        rowData.dueDate ? (
          <EllipseTextComponent
            textFieldWidth="5rem"
            text={<FormattedDate date={new Date(rowData.dueDate)} timezone={user.timezone} />}
          />
        ) : null,
    },
    {
      title: 'Status',
      field: 'status',
      sorting: false,
      render: (rowData: DocumentsDataInterface) => <InvoicesStatusComponent status={rowData.status} />,
    },
    {
      title: 'Actions',
      field: 'actions',
      sorting: false,
      cellStyle: {
        width: '1%',
      },
      render: (rowData: DocumentsDataInterface): React.ReactElement => {
        const { archiveDocId, archiveId, documentTypeId, fileName } = rowData;
        const downloadFileProp = {
          archiveId,
          documentType: documentTypeId,
          fileName,
          archiveDocId,
        };
        return (
          <>
            <Box display={'flex'} gap={'6px'}>
              <ToolTipCommon title={'View'}>
                <IconButton style={{ padding: 5 }} onClick={() => downloadFile(downloadFileProp, true)}>
                  <Eye />
                </IconButton>
              </ToolTipCommon>
              <ToolTipCommon title={'Download'}>
                <IconButton style={{ padding: 5 }} onClick={() => downloadFile(downloadFileProp)}>
                  <Download />
                </IconButton>
              </ToolTipCommon>
            </Box>
          </>
        );
      },
    },
  ];


  const updatedColumns = filterColumns
    ? filterColumns(invoicesTableDefaultColumns())
    : invoicesTableDefaultColumns();

  const tableData: DocumentsDataInterface[] = data.map((d) => ({ ...d })) || [];
  const isFilter = headerRightComponentType === HeaderRightComponentTypeEnum.filter;
  return (
    <>
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <Card>
          {isHeaderComponent ? <>
            <TableHeaderComponent
              leftComponent={
                headerLeftComponent ? (
                  headerLeftComponent
                ) : isFilter ? (
                  <InvoicesFilterSelectionComponent />
                ) : (
                  <div></div>
                )
              }
              rightComponent={isFilter ?
                <Stack flexDirection={"row"}
                  alignItems={isFilterSelection ? 'center' : 'baseline'}
                  spacing={isFilterSelection ? 0 : 1}
                  marginLeft={'auto'}
                >
                  {isFilterSelection ?
                    <ResetButton onClickHandler={onResetClick} />
                    : null
                  }
                  <FilterButtonComponent onClickHandler={onRightHeaderComponentClick} />
                </Stack>
                : headerRightComponentType === HeaderRightComponentTypeEnum.viewAll ?
                  <ViewAllButtonComponent onClickHandler={onRightHeaderComponentClick} />
                  : null
              } />
            <Divider />
          </> : null}
          {isLoading && <LoadingSkeleton />}
          {!isLoading && (
            <Table
              page={pageNumber}
              onPageChange={handlePageChange}
              onOrderChange={handleOrderChange}
              order={order}
              totalCount={totalCount}
              columns={updatedColumns}
              isLoading={isLoading}
              data={tableData}
              noDataComponent={TableNoData}
              paginationVariant="numbered"
              onRowsPerPageChange={handlePageRowsCountChange}
              options={{
                paging: paginationEnabled,
                pageSize,
                showTitle: false,
                search: false,
                sorting: true,
                toolbar: false,
                draggable: false,
                thirdSortClick: false,
              }}
            />
          )}
        </Card>
      </Paper>
    </>)
}
