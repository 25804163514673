import { Button, Typography } from '@mui/material'

export const ViewAllButtonComponent = ({ onClickHandler }: { onClickHandler: () => void }): React.ReactElement =>
  <Button onClick={onClickHandler} variant="text" color="primary">
    <Typography
      color={'primary'}
      fontSize={14}
      fontWeight={800}
      letterSpacing={0.46}
      variant='semiBold'
    >{`View All`}</Typography>
  </Button>