import { Grid } from '@mui/material';
import React from 'react'

interface TableHeaderProps {
  leftComponent: React.ReactElement;
  rightComponent: React.ReactElement;
}

export const TableHeaderComponent = ({ leftComponent, rightComponent }: TableHeaderProps): React.ReactElement =>
  <Grid container alignItems={"center"} justifyContent="space-between" p={'16px'}>
    {leftComponent}
    {rightComponent}
  </Grid>
