import { TableStatusComponent } from 'modules/common/components/table-status/table-status.component'
import { DocumentsApiStatusEnum } from 'modules/common/enums/documents-api-status.enum'
import { FunctionComponent } from 'react'

const getChipColor = (status: DocumentsApiStatusEnum) => {
  switch (status) {
    case DocumentsApiStatusEnum.CLEARED:
      return 'green'
    case DocumentsApiStatusEnum.OUTSTANDING:
      return 'gray'
    case DocumentsApiStatusEnum.OVERDUE:
      return 'red'
  }
}

export const InvoicesStatusComponent: FunctionComponent<{ status: DocumentsApiStatusEnum; fullWidth?: boolean, stylingProps?: React.CSSProperties }> = ({ status, fullWidth, stylingProps }) => <TableStatusComponent stylingProps={stylingProps} fullWidth={fullWidth} label={status} color={getChipColor(status)} />