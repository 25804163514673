import { Chip } from '@mui/material';
import { useColoredChipStyles } from 'modules/common/components/colored-chip/colored-chip.styles';
import React, { FunctionComponent } from 'react';

export interface ColoredChipComponentInterface {
  color?: 'gray' | 'green' | 'orange' | 'red' | 'cyan' | 'blue' | 'lightBlue';
  label?: string;
  fullWidth?: boolean;
  stylingProps?: React.CSSProperties;
  spanStyling?: React.CSSProperties
}

export const ColoredChipComponent: FunctionComponent<ColoredChipComponentInterface> = ({
  color = 'gray',
  label,
  fullWidth = false,
  stylingProps = {},
  spanStyling
}) => {
  const classes = useColoredChipStyles();
  const className = [classes.statusChip, classes[`${color}Chip`], fullWidth ? classes.fullWidth : ''].join(' ');
  return <Chip component="div" label={label} className={className} sx={{ ...stylingProps, ...(spanStyling ? { '& > span': spanStyling } : {}) }} />;
};
