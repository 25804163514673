import { TableColumnInterface } from 'modules/common/components';
import { DocumentsDataInterface } from 'modules/documents/interfaces/documents-data.interface';
import { useFilterBy } from 'modules/filter-by/hooks';
import { InvoicesTableComponent } from 'modules/invoices/components/invoices-table/invoices-table.component';
import { HeaderRightComponentTypeEnum } from 'modules/invoices/enums/header-right-component-type.enum';
import { useInvoicesDocument } from 'modules/invoices/hooks/use-invoices-document.hook';
import { InvoicesTableStateInterface } from 'modules/invoices/interfaces/invoices-table-state.interface';
import React from 'react';

interface InvoiceTablePartialProps {
  invoicesTableState: Partial<InvoicesTableStateInterface>;
  headerRightComponentType: HeaderRightComponentTypeEnum;
  onRightHeaderComponentClick: () => void;
  filterColumns?: (
    callbackFunction: TableColumnInterface<DocumentsDataInterface>[],
  ) => TableColumnInterface<DocumentsDataInterface>[];
  headerLeftComponent?: React.ReactElement;
  onRowClick?: (
    event?: React.MouseEvent<Element, MouseEvent>,
    rowData?: DocumentsDataInterface,
    toggleDetailPanel?: (panelIndex?: number) => void,
  ) => void;
  paginationEnabled: boolean;
  onResetClick?: () => void; 
}

export const InvoiceTableSectionComponent = (props: InvoiceTablePartialProps): React.ReactElement => {
  const {
    onRightHeaderComponentClick,
    invoicesTableState,
    headerRightComponentType,
    filterColumns,
    headerLeftComponent,
    paginationEnabled = false,
    onRowClick,
    onResetClick,
  } = props;
  const { invoicesState } = useFilterBy();
  const { products, shipToAddresses, status, deliveryId, orderId, documentTypes, orderType } = invoicesState;
  const isFilterSelection =
    !!products?.length || !!shipToAddresses?.length || !!status?.length || !!orderId || !!deliveryId || !!documentTypes?.length || !!orderType?.length;

  const {
    handleOrderChange,
    data,
    handlePageChange,
    isLoading,
    pageNumber,
    pageSize,
    totalCount,
    handlePageRowsCountChange,
    order,
    reload,
  } = useInvoicesDocument({ invoicesTableState });

  return (
    <>
      <InvoicesTableComponent
        isHeaderComponent={true}
        headerRightComponentType={headerRightComponentType}
        onRightHeaderComponentClick={onRightHeaderComponentClick}
        data={data || []}
        isLoading={isLoading}
        handleOrderChange={handleOrderChange}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalCount={totalCount || data?.length}
        paginationEnabled={paginationEnabled}
        handlePageChange={handlePageChange}
        handlePageRowsCountChange={handlePageRowsCountChange}
        order={order}
        reload={reload}
        filterColumns={filterColumns}
        headerLeftComponent={headerLeftComponent}
        onRowClick={onRowClick}
        isFilterSelection={isFilterSelection}
        onResetClick={onResetClick}
      />
    </>
  );
};
