import { OrderEnum } from 'modules/common/enums';
import { DocumentsOrderSortEnum } from 'modules/common/enums/documents-order-sort.enum';
import { DocumentsStatusEnum } from 'modules/common/enums/documents-status.enum';
import { DocumentsTypeEnum } from 'modules/common/enums/documents-type.enum';
import { useAsyncOperation } from 'modules/common/hooks';
import { useFetchDocuments } from 'modules/documents/hooks/use-fetch-documents.hook';
import { DocumentTypeFetchInterface } from 'modules/documents/interfaces/document-type-fetch.interface';
import { setInvoicesTableState } from 'modules/filter-by/filter-by.slice';
import { useFilterBy } from 'modules/filter-by/hooks';
import { InvoicesTableStateInterface } from 'modules/invoices/interfaces/invoices-table-state.interface';
import { UseInvoicesHookResponseInterface } from 'modules/invoices/interfaces/use-invoices-response.interface';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface InvoicesHookOptionsInterface {
  invoicesTableState?: Partial<InvoicesTableStateInterface>;
}

export interface InvoicesTableFilterInterface {
  productIds?: string[];
  shipToAddressIds?: string[];
  statuses?: DocumentsStatusEnum[];
  searchText?: string;
  documentTypes?: DocumentTypeFetchInterface[];
}


export const useInvoicesDocument = (options: InvoicesHookOptionsInterface): UseInvoicesHookResponseInterface => {
  const invoicesTableState = options?.invoicesTableState;
  const { fetchDocuments } = useFetchDocuments();
  const { isLoading, initiateOperation, status: dataStatus } = useAsyncOperation({ callback: fetchDocuments });
  const dispatch = useDispatch();
  const { invoicesState } = useFilterBy();
  const {
    tableState: invoicesReduxTableState,
  } = invoicesState;
  const totalCount = dataStatus?.result?.totalCount;
  const data = dataStatus?.result?.data;
  const [tableState, setTableState] = useState<InvoicesTableStateInterface>({
    pageNumber: 0,
    pageSize: invoicesReduxTableState?.rowPerPage,
    order: {
      order: OrderEnum.ASC,
      sort: DocumentsOrderSortEnum.CREATED_AT,
    },
    ...(!!invoicesTableState && invoicesTableState),
  });

  useEffect(() => {
    if (invoicesTableState) {
      setTableState({
        ...tableState,
        ...invoicesTableState,
      });
    }
  }, [invoicesTableState]);

  const { pageNumber, pageSize, order, filter } = tableState;

  useEffect(() => {
    const filterObj = {
      offset: pageNumber * pageSize,
      limit: pageSize,
      order,
      filter: {
        ...filter,
        type: DocumentsTypeEnum.INVOICES,
      },
    };
    if (filter?.kunnr) {
      void initiateOperation(filterObj);
    }
  }, [
    pageNumber,
    pageSize,
    order,
    filter,
  ]);

  const reload = useCallback(() => {
    setTableState((ts) => ({
      ...ts,
      filter: {
        ...(!!invoicesTableState?.filter && invoicesTableState.filter),
        type: DocumentsTypeEnum.INVOICES,
      },
      pageNumber: 0,
      pageSize: 20,
      order: { sort: DocumentsOrderSortEnum.ID, order: OrderEnum.DESC },
    }));
  }, [setTableState, invoicesTableState]);

  const handleOrderChange = useCallback(
    (sort, orderDirection) => {
      setTableState((ts) => ({
        ...ts,
        order: {
          sort,
          order: orderDirection,
        },
      }));
    },
    [setTableState],
  );

  const handlePageRowsCountChange = useCallback(
    (newPageSize: number) => {
      setTableState((ts) => ({
        ...ts,
        pageSize: newPageSize,
      }));
    },
    [setTableState],
  );

  const handleSearchText = useCallback(
    (searchTextValue: string) => {
      setTableState((ts) => ({
        ...ts,
        pageNumber: 0,
        filter: { ...filter, searchText: searchTextValue },
      }));
    },
    [setTableState],
  );

  const handlePageChange = useCallback(
    (newPageNumber: number, newPageSize: number) => {
      dispatch(setInvoicesTableState({ rowPerPage: newPageSize }));
      setTableState((ts) => ({
        ...ts,
        pageNumber: newPageNumber,
        pageSize: newPageSize,
      }));
    },
    [setTableState, dispatch],
  );

  return {
    ...tableState,
    handlePageChange,
    handlePageRowsCountChange,
    handleSearchText,
    handleOrderChange,
    isLoading,
    reload,
    data,
    totalCount,
  };
};
