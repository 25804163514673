import { DocumentsStatusEnum } from 'modules/common/enums/documents-status.enum';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { DocumentTypeOptionInterface } from 'modules/documents/interfaces/document-type-option.interface';
import { resetInvoicesFilter, setInvoicesState } from 'modules/filter-by/filter-by.slice';
import { useFilterBy } from 'modules/filter-by/hooks';
import { AddressOptionInterface } from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { IncotermsInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms.interface';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search/interfaces';
import { OptionInterface } from 'modules/forms/components/select/select.component';
import { InvoicesFilterInterface } from 'modules/invoices/interfaces/invoices-filter.interface';
import { InvoicesFilterReturnInterface } from 'modules/invoices/interfaces/invoices-filter-return.interface';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useInvoicesFilter = (isFilterOpen?: boolean): InvoicesFilterReturnInterface => {
  const { invoicesState } = useFilterBy();
  const { products, shipToAddresses, incoterms, status, orderType, documentTypes } = invoicesState;
  const [selectedProduct, setSelectedProduct] = useState(products);
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<DocumentTypeOptionInterface[]>(documentTypes);
  const [selectedShipToAddresses, setSelectedShipToAddresses] = useState(shipToAddresses);
  const [selectedIncoterms, setSelectedIncoterms] = useState(incoterms);
  const [selectedOrderType, setSelectedOrderType] = useState(orderType);
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const defaultFilter: InvoicesFilterInterface = {};
  const dispatch = useDispatch();
  const onProductChange = (product: ProductAutocompleteOptionInterface[]) => {
    setSelectedProduct(product);
  };
  const buildFilter = () => {
    const filter = { ...defaultFilter };

    const kunnr = currentBusinessPartnerInternal?.kunnr || selectedProduct?.[0]?.kunnr;
    if (kunnr) {
      filter.kunnrIds = kunnr;
    }
    filter.productId = selectedProduct?.map(({ id }) => id);

    if (selectedStatus) {
      filter.status = selectedStatus;
    }

    if (selectedShipToAddresses?.length >= 1) {
      filter.shipToAddressIds = selectedShipToAddresses.map((a) => a.id);
    }
    return filter;
  };

  const [filter, setFilter] = useState(buildFilter());

  const onStatusChange = (value: OptionInterface<DocumentsStatusEnum>[]) => {
    setSelectedStatus(value);
  };

  const onOrderTypeChange = (value) => {
    setSelectedOrderType(value);
  };

  const onShipToAddressesChange = (newAddresses: AddressOptionInterface[]) => {
    setSelectedShipToAddresses(newAddresses);
  };

  const onIncotermsChange = (value: IncotermsInterface[]) => {
    setSelectedIncoterms(value);
  };

  const onDocumentTypeChange = (value: DocumentTypeOptionInterface[]) => {
    setSelectedDocumentTypes(value);
  }

  const onApply = (): InvoicesFilterInterface => {
    const newFilter = buildFilter();
    setFilter(newFilter);
    dispatch(
      setInvoicesState({
        status: selectedStatus,
        shipToAddresses: selectedShipToAddresses,
        products: selectedProduct,
        orderType: selectedOrderType,
        documentTypes: selectedDocumentTypes
      }),
    );
    return newFilter;
  };

  const onFilterChange = (filterObject: InvoicesFilterInterface): void => {
    const newFilter = buildFilter();
    const formatFilter = { ...newFilter, ...filterObject };
    delete formatFilter.searchText;
    setFilter(formatFilter);
  };

  const onReset = () => {
    setFilter({ kunnrIds: currentBusinessPartnerInternal?.kunnr });
    dispatch(resetInvoicesFilter());
  };

  const syncFilterAndReduxState = () => {
    setSelectedProduct(products);
    setSelectedStatus(status);
    setSelectedShipToAddresses(shipToAddresses);
    setSelectedIncoterms(incoterms);
    setSelectedOrderType(orderType);
    setSelectedDocumentTypes(documentTypes);
  };

  useEffect(() => {
    if (isFilterOpen) {
      syncFilterAndReduxState();
    }
  }, [isFilterOpen]);

  return {
    selectedProduct,
    onProductChange,
    onStatusChange,
    selectedStatus,
    onShipToAddressesChange,
    selectedShipToAddresses,
    onIncotermsChange,
    selectedIncoterms,
    filter,
    onApply,
    onReset,
    onFilterChange,
    onOrderTypeChange,
    selectedOrderType,
    selectedDocumentTypes,
    onDocumentTypeChange,
};
};
