/* eslint-disable @roq/filename-suffix-mismatch */

import { Theme } from '@mui/material';
import { roqMakeStyles } from 'modules/common/utils';

export const useColoredChipStyles = roqMakeStyles((theme: Theme) => ({
  statusChip: {
    color: theme.palette.common.white,
    height: '27px',
    padding: '8px 24px',
    alignItems: 'center',
    borderRadius: 2,
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '0.16px',
  },
  fullWidth: {
    width: '100%',
  },
  grayChip: {
    background: '#0A0B0F14',
    color: theme.palette.common.black,
  },
  greenChip: {
    background: '#EDF7ED',
    color: '#00865E',
  },
  redChip: {
    background: '#FDEDED',
    color: '#C11333',
  },
  orangeChip: {
    background: '#FFF4E5',
    color: '#ED8001',
  },
  blueChip: {
    background: '#E5F6FD',
    color: '#004CBD',
  },
  lightBlueChip: {
    background: '#CCDEE3',
    color: '#1F4556',
  },
  cyanChip: {
    background: '#E3F4F3',
    color: '#3C9588',
  },
}));
