import { Chip, Stack } from '@mui/material';
import { useDeliveryTableSectionStyles } from 'modules/deliveries/components/delivery-table-section/delivery-table-section.styles';
import {
  removeInvoicesDeliveryId,
  removeInvoicesDocumentTypes,
  removeInvoicesOrderId,
  removeInvoicesOrderType,
  removeInvoicesProduct,
  removeInvoicesShipToAddress,
  removeInvoicesStatus,
} from 'modules/filter-by/filter-by.slice';
import { useFilterBy } from 'modules/filter-by/hooks';
import { useInvoicesFilter } from 'modules/invoices/hooks/use-invoices-filter.hook';
import React from 'react';
import { useDispatch } from 'react-redux';

const SelectionChip = ({
  label,
  onDeleteHandler,
}: {
  label: string;
  onDeleteHandler: () => void;
}): React.ReactElement => (
  <Chip
    size="small"
    variant="filled"
    label={label}
    onDelete={onDeleteHandler}
  />
);

export const InvoicesFilterSelectionComponent = (): React.ReactElement => {
  const { onProductChange, onShipToAddressesChange, onStatusChange, onDocumentTypeChange } = useInvoicesFilter();
  const { invoicesState } = useFilterBy();
  const { products, shipToAddresses, status, deliveryId, orderId, orderType, documentTypes } = invoicesState;
  const dispatch = useDispatch();
  const classes = useDeliveryTableSectionStyles();
  return (
    <Stack gap={1} flexDirection={'row'} flexWrap={'wrap'} className={classes.customChip} maxWidth={'75%'}>
      <>
        {products?.length
          ? products.map((product) => (
              <SelectionChip
                key={product?.id}
                label={`Product: ${product?.label}`}
                onDeleteHandler={async () => {
                  const filterdProducts = products.filter((item) => product.id !== item.id);
                  onProductChange(filterdProducts);
                  dispatch(removeInvoicesProduct(product));
                }}
              />
            ))
          : null}
      </>
      <>
        {shipToAddresses?.length
          ? shipToAddresses.map((shipToAddress) => (
              <SelectionChip
                key={shipToAddress?.id}
                label={`Ship To Address: ${shipToAddress.displayAddress}`}
                onDeleteHandler={() => {
                  dispatch(removeInvoicesShipToAddress(shipToAddress));
                  const filterdShipToAddresses = shipToAddresses.filter((item) => shipToAddress.id !== item.id);
                  onShipToAddressesChange(filterdShipToAddresses);
                }}
              />
            ))
          : null}
      </>
      <>
        {orderType?.length
          ? orderType.map((type) => (
              <SelectionChip
                key={type?.value}
                label={`Order Type: ${type.label}`}
                onDeleteHandler={() => {
                  dispatch(removeInvoicesOrderType(type));
                }}
              />
            ))
          : null}
      </>
      <>
        {status?.length
          ? status.map((statusItem) => (
              <SelectionChip
                key={statusItem?.value}
                label={`Status: ${statusItem.label}`}
                onDeleteHandler={() => {
                  dispatch(removeInvoicesStatus(statusItem));
                  const filterdStatuses = status.filter((item) => statusItem.value !== item.value);
                  onStatusChange(filterdStatuses);
                }}
              />
            ))
          : null}
      </>
      <>
        {documentTypes?.length
          ? documentTypes.map((documentType) => (
            <SelectionChip
              key={documentType?.name}
              label={`DocumentType: ${documentType.name}`}
              onDeleteHandler={() => {
                dispatch(removeInvoicesDocumentTypes(documentType));
                const filterdDocumentstype = documentTypes.filter((item) => documentType.name !== item.name);
                onDocumentTypeChange(filterdDocumentstype);
              }}
            />
          ))
          : null}
      </>
      {orderId ? (
        <>
          <SelectionChip
            label={`Order ID: ${orderId}`}
            onDeleteHandler={() => {
              dispatch(removeInvoicesOrderId());
            }}
          />
        </>
      ) : null}
      {deliveryId ? (
        <>
          <SelectionChip
            label={`Delivery ID: ${deliveryId}`}
            onDeleteHandler={() => {
              dispatch(removeInvoicesDeliveryId());
            }}
          />
        </>
      ) : null}
    </Stack>
  );
};
